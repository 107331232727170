* {
  font-family: 'Rubik', sans-serif;
  direction: rtl;
}

html {
  scroll-behavior: smooth;
}

#home, #about, #activities {
  scroll-margin-top: 80px;
}

.App {
  text-align: right;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.5rem;
  color: #333;
  position: sticky;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 100;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.header-left {
  display: flex;
  align-items: center;
  padding-left: 1rem;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.nav-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-link {
  color: #333;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.nav-link:hover {
  color: #0066cc;
}

.header-button {
  background-color: #0066cc;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.header-button:hover {
  background-color: #0052a3;
}

.header-logo {
  height: 40px;
  width: auto;
  object-fit: contain;
}

@media (max-width: 768px) {
  .App-header {
    padding: 0 1.5rem;
  }

  .header-left {
    padding-left: 0;
  }

  .nav-links {
    display: none;
  }

  .header-button {
    display: none;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.hero-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  padding: 4rem 2rem;
  gap: 4rem;
  max-width: 1400px;
  margin: 0 auto;
}

.hero-section-image {
  flex: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-image {
  max-width: 90%;
  height: auto;
  object-fit: contain;
  border-radius: 20px;
  animation: float 3s ease-in-out infinite;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.hero-section-title {
  flex: 1.2;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 600px;
}

.hero-logo {
  width: 120px;
  height: auto;
  margin: 0 auto;
}

.hero-section-title h1 {
  font-size: 3.5rem;
  line-height: 1.2;
  margin: 0;
  color: #333;
}

.hero-section-title p {
  font-size: 1.25rem;
  line-height: 1.6;
  margin: 0;
  color: #666;
}

.hero-section-title button {
  background-color: #0066cc;
  color: white;
  padding: 1rem 3rem;
  border-radius: 25px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-size: 1.2rem;
  margin: 1rem auto;
}

.hero-section-title button:hover {
  background-color: #0052a3;
}

@media (max-width: 1200px) {
  .hero-section-title h1 {
    font-size: 3rem;
  }
}

@media (max-width: 768px) {
  .hero-section {
    flex-direction: column-reverse;
    padding: 2rem 1rem;
    gap: 2rem;
    min-height: auto;
  }

  .hero-section-title {
    text-align: center;
    padding: 0 1rem;
  }

  .hero-section-title h1 {
    font-size: 2.5rem;
  }

  .hero-section-title p {
    font-size: 1.1rem;
  }

  .hero-section-image {
    width: 100%;
    flex: none;
  }

  .hero-image {
    max-width: 70%;
  }

  .hero-logo {
    width: 100px;
  }
}

.features-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  padding: 4rem 2rem;
  flex-wrap: wrap;
  gap: 3rem;
}

.feature-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  flex: 1;
  min-width: 200px;
}

.feature-card h3 {
  margin: 0;
  font-size: 2.5rem;
  color: #0066cc;
  font-weight: bold;
}

.feature-card p {
  margin: 0;
  color: #666;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .features-section {
    padding: 2rem 1rem;
    gap: 2rem;
  }

  .feature-card {
    min-width: 40%;
    padding: 0 0.5rem;
  }

  .feature-card h3 {
    font-size: 2rem;
  }

  .feature-card p {
    font-size: 1rem;
  }
}

.about-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  padding: 4rem 2rem;
  gap: 4rem;
  max-width: 1400px;
  margin: 0 auto;
}

.about-section-image {
  flex: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-image {
  max-width: 90%;
  height: auto;
  object-fit: contain;
  border-radius: 20px;
  animation: float 3s ease-in-out infinite;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.about-section-title {
  flex: 1.2;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 600px;
}

.about-logo {
  width: 120px;
  height: auto;
  margin: 0 auto;
}

.about-section-title h1 {
  font-size: 3.5rem;
  line-height: 1.2;
  margin: 0;
  color: #333;
}

.about-section-title p {
  font-size: 1.25rem;
  line-height: 1.6;
  margin: 0;
  color: #666;
}

.about-section-title button {
  background-color: #0066cc;
  color: white;
  padding: 1rem 3rem;
  border-radius: 25px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-size: 1.2rem;
  margin: 1rem auto;
}

.about-section-title button:hover {
  background-color: #0052a3;
}

@media (max-width: 1200px) {
  .about-section-title h1 {
    font-size: 3rem;
  }
}

@media (max-width: 768px) {
  .about-section {
    flex-direction: column-reverse;
    padding: 2rem 1rem;
    gap: 2rem;
    min-height: auto;
  }

  .about-section-title {
    text-align: center;
    padding: 0 1rem;
  }

  .about-section-title h1 {
    font-size: 2.5rem;
  }

  .about-section-title p {
    font-size: 1.1rem;
  }

  .about-section-image {
    width: 100%;
    flex: none;
  }

  .about-image {
    max-width: 70%;
  }

  .about-logo {
    width: 100px;
  }
}

.activities-section {
  padding: 4rem 2rem;
  text-align: center;
}

.activities-section h1 {
  margin-bottom: 2rem;
  font-size: 2.5rem;
  color: #333;
}

.activities-section-cards {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.activity-card {
  display: flex;
  flex-direction: row-reverse;
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.activity-image-1 {
  width: 40%;
  height: auto;
  object-fit: cover;
  min-height: 300px;
}

.activity-card-data {
  padding: 2rem;
  text-align: right;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.activity-card-data h3 {
  margin: 0;
  font-size: 1.8rem;
  color: #333;
}

.activity-card-data p {
  margin: 0;
  color: #666;
  line-height: 1.6;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .activities-section {
    padding: 2rem 1rem;
  }

  .activities-section-cards {
    gap: 1.5rem;
  }

  .activity-card {
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
  }

  .activity-image-1 {
    width: 100%;
    height: 200px;
    min-height: unset;
  }

  .activity-card-data {
    padding: 1.5rem;
  }

  .activity-card-data h3 {
    font-size: 1.5rem;
  }

  .activity-card-data p {
    font-size: 1rem;
  }
}

.gallery-section {
  padding: 2rem;
  text-align: center;
}

.gallery-section h1 {
  margin-bottom: 2rem;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.gallery-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.gallery-image:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
  
  .gallery-image {
    height: 150px;
  }
}

/* Footer Styles */
.footer {
  background-color: #f8f9fa;
  padding: 60px 20px 20px;
  direction: rtl;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

.footer-section {
  padding: 0 15px;
}

.footer-section h3 {
  color: #333;
  font-size: 1.2rem;
  margin-bottom: 20px;
  position: relative;
}

.footer-section h3::after {
  content: '';
  position: absolute;
  bottom: -8px;
  right: 0;
  width: 40px;
  height: 2px;
  background-color: #007bff;
}

.footer-section p {
  color: #666;
  margin-bottom: 10px;
  font-size: 0.9rem;
}

.social-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.social-link {
  color: #666;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.social-link:hover {
  color: #007bff;
}

.footer-nav {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer-nav a {
  color: #666;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.footer-nav a:hover {
  color: #007bff;
}

.footer-bottom {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #ddd;
  text-align: center;
}

.footer-bottom p {
  color: #888;
  font-size: 0.8rem;
}

/* Responsive Footer Styles */
@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .footer-section {
    text-align: center;
  }

  .footer-section h3::after {
    right: 50%;
    transform: translateX(50%);
  }

  .social-links {
    align-items: center;
  }

  .footer-nav {
    align-items: center;
  }
}

/* Mobile Menu Styles */
.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 1000;
}

.mobile-menu-button span {
  display: block;
  width: 25px;
  height: 2px;
  background-color: #333;
  margin: 5px 0;
  transition: all 0.3s ease;
}

.mobile-menu {
  position: fixed;
  top: 0;
  right: -280px;
  width: 280px;
  height: 100vh;
  background-color: white;
  z-index: 1001;
  padding: 60px 20px 20px;
  transition: right 0.3s ease;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  transition: right 0.3s ease, visibility 0.3s ease, opacity 0.3s ease;
}

.mobile-menu.open {
  right: 0;
  visibility: visible;
  opacity: 1;
}

.mobile-menu-close {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.mobile-nav {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.mobile-nav-link {
  color: #333;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 500;
  transition: color 0.2s ease;
}

.mobile-nav-link:hover {
  color: #0066cc;
}

.mobile-donate-button {
  background-color: #0066cc;
  color: white;
  padding: 0.8rem 2rem;
  border-radius: 25px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-top: 20px;
  width: 100%;
}

.mobile-donate-button:hover {
  background-color: #0052a3;
}

.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.mobile-menu-overlay.open {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 768px) {
  .mobile-menu-button {
    display: block;
  }

  .nav-links, 
  .header-button {
    display: none;
  }
}

/* Update nav-links styles */
.nav-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.social-nav-links {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  margin-right: 2rem;
  padding-right: 2rem;
  border-right: 1px solid #ddd;
}

.social-nav-link {
  color: #666;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.9rem;
  transition: color 0.2s ease;
}

.social-nav-link:hover {
  color: #0066cc;
}

/* Update mobile menu styles */
.mobile-social-links {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  margin: 20px 0;
  padding: 20px 0;
  width: 100%;
  justify-content: center;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.mobile-social-link {
  color: #666;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  transition: color 0.2s ease;
}

.mobile-social-link:hover {
  color: #0066cc;
}

/* Update responsive styles */
@media (max-width: 1024px) {
  .social-nav-links {
    margin-right: 1rem;
    padding-right: 1rem;
    gap: 1rem;
  }
  
  .nav-links {
    gap: 1.5rem;
  }
}

@media (max-width: 768px) {
  .nav-links,
  .header-button,
  .social-nav-links {
    display: none;
  }
}