.about-page {
  padding-top: 70px;
}

.about-hero {
  /* background-color: #f8f9fa; */
  padding: 80px 20px;
  text-align: center;
}

.about-hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.about-hero h1 {
  font-size: 3rem;
  color: #333;
  margin-bottom: 20px;
}

.about-hero p {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.6;
}

.about-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
}

.about-section {
  display: flex;
  gap: 60px;
  align-items: center;
  margin-bottom: 60px;
}

.about-image {
  flex: 1;
}

.about-image img {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.about-text {
  flex: 1;
}

.about-text h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.about-text p {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
}

.about-stats {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-top: 60px;
}

.stat-card {
  text-align: center;
  padding: 30px;
  background-color: #f8f9fa;
  border-radius: 15px;
  transition: transform 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-5px);
}

.stat-card h3 {
  font-size: 2.5rem;
  color: #0066cc;
  margin-bottom: 10px;
}

.stat-card p {
  color: #666;
  font-size: 1.1rem;
}

@media (max-width: 1024px) {
  .about-section {
    gap: 40px;
  }
}

@media (max-width: 768px) {
  .about-hero h1 {
    font-size: 2.5rem;
  }

  .about-section {
    flex-direction: column;
    gap: 30px;
  }

  .about-stats {
    grid-template-columns: repeat(2, 1fr);
  }

  .about-text h2 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .about-stats {
    grid-template-columns: 1fr;
  }
} 